import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Stock() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Stock</b>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 d-flex">
            <h5 className="mt-2">Stock</h5>
            <div className="mx-3">
              <button
                className="btn btn-success add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
            </div>
          </div>
          <div className="col-lg-2">
            <div>
              <input
                type="file"
                id="file-upload"
                accept=".xls,.xlsx"
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload" className="btn btn-secondary">
                File Upload
              </label>
            </div>
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select className="form-control w-100 mx-2">
                  {/* <option value="5">5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------end------------------------------------------- */}

        {/* Table */}
        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Goods Id</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>M.R.P</th>
                  <th>Buy price</th>
                  <th>Sale Price</th>
                  <th>Total Qty</th>
                  <th>Available Qty</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Siva</td>
                  <td>Javascript</td>
                  <td>Facebook</td>
                  <td>2700</td>
                  <td>3500</td>
                  <td>5000</td>
                  <td>5000</td>
                  <td>3000</td>
                  <td>
                    <button className="btn updelete ">
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button className="btn delete mx-1">
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button className="btn btn-transpront">
                      <i class="fa-solid fa-circle-info"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <div className="insermodal">
          <Modal
            className="addmodals"
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Stock</Modal.Title>
            </Modal.Header>

            <form>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        required
                      />
                      <label className="">Goods ID</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        required
                      />
                      <label className="">Name</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">Category</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">MRP</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">Buying Price</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">Sales Price</label>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">Description</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">Notes</label>
                    </div>

                    <div className="floating-label">
                      <select className="form-control" required>
                        <option value="">Select Option</option>
                        <option value="optionA">Google Ad</option>
                        <option value="optionB">Website</option>
                        <option value="optionC">Facebook</option>
                        <option value="optionC">Instagram</option>
                        <option value="optionC">Linked In</option>
                        <option value="optionC">Referral</option>
                      </select>
                      <label>Select Status</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">Buying Quantity</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">Solding Quantity</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        required
                      />
                      <label className="">Available Quantity</label>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  className="cancelbut"
                  variant="secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
                <Button className="submitbut" type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>

        {/* _------------------------------pagination button -------------------------------- */}
        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li className="">
                    <button className="page-link">Previous</button>
                  </li>

                  <li className="">
                    <button className="page-link ">1</button>
                  </li>

                  <li className="">
                    <button className="page-link ">Next</button>
                  </li>
                </ul>
              </nav>{" "}
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark ">Page 1 of 1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stock;
