import React from "react";
import "../assets/css/Navbar.css";

function Navbar({ toggle }) {
  return (
    <div className="navborder  ">
      <nav
        className={`${
          toggle ? "toggle-active" : "toggle-notactive"
        } navbar default-layout-navbar col-lg-12 p-0 fixed-top d-flex flex-row`}
      >
        <div className="text-center d-flex align-items-center justify-content-center">
          <div className=" brand-logo ">
            {/* <img onClick={haldlenavigatedashboard} src={navlogo} alt="logo" /> */}
            <div className="logonav d-flex justify-content-between">
              {/* <img src={require("../../src/assets/img/scimitar logo png.jpg")} alt="Company Logo" style={{ width: "150px", height:"40px", marginLeft : "50px",marginTop:"5px" }}></img> */}
              <p
                className="navbarroutertag"
                style={{ color: "black", marginRight: "70px" }}
              ></p>
            </div>
          </div>
        </div>
        <div className="navbar-menu-wrapper  d-flex align-items-stretch">
          <div className="mt-3" style={{ marginLeft: "-250px" }}>
            <i class="fa-solid fa-sitemap  usericon mx-2"></i>
            <b className="admin-username-nav" style={{ color: "white" }}>
              {localStorage.getItem("branch_code")}-
              All Branches
            </b>
          </div>

          {/* <ul className="navbar-nav navbar-nav-right responsive-none" style={{ height: '50px' }}> */}
          {/* <li className="nav-item"> */}

          {/* <i className="fa-regular fa-circle-user usericon mx-2 "></i>

                        <b className='admin-username-nav' style={{ color: 'white' }}>
                        {localStorage.getItem("username")}

                        </b> */}
          {/* </li> */}
          {/* </ul> */}
          <ul
            className="navbar-nav navbar-nav-right responsive-none"
            style={{ height: "50px" }}
          >
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <i className="fa-regular fa-circle-user usericon mx-2"></i>
              <b className="admin-username-nav" style={{ color: "white" }}>
               Admin
              </b>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggle}
          ></button>

          {/* Modal for Logout Confirmation */}
        </div>
      </nav>

      <div className="spacer"></div>
    </div>
  );
}

export default Navbar;
