function AddSalesInvoice() {
    return(
        <>
        <div>
          <h5 className="head" style={{ textDecoration: "underline", marginTop: '-80px' }}>
            New Sales Order
          </h5>
        </div>
        <hr></hr>
        {/* invoice no */}
        <div className="card invoice_card p-3">
          <div className="row first">
            <div className="col-lg-3 ">
              <label>Sales Order No:</label>
              <input
                type="text"
                className="form-control"
              ></input>
            </div>
            {/* date */}
            <div className="col-lg-6"></div>

            <div className="col-lg-3">
              <label>Date:</label>
              <input
                type="date"
                className="form-control"
               
                readOnly
              />
            </div>
          </div>
        </div>
        {/* type */}

        <div className="card invoice_card p-3">
          <div className="row first">
            <div className="col-lg-3">
              <label>Customer Name:</label>

              <select
                className="form-select"
               
              >
                <option value="">Select Customer</option>
                <option value="">Customer 1</option>
                 <option value="">Customer 2</option>
                
                  
               
              </select>
            </div>

            {/*  email*/}
            <div className="col-lg-3">
              <label>Email:</label>
              <input
                type="text"
              
                className="form-control"
                disabled
              ></input>
            </div>
            {/* mobile */}
            <div className="col-lg-3">
              <label>Mobile:</label>
              <input
                type="tel"
               
                className="form-control"
                disabled
              ></input>
            </div>
            {/* add button */}
            <div className="col-lg-1 mt-4">
             
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>

        <div className="card invoice_card p-3">
          <div className="row first">
          
            <div className="col-lg-3">
              <label>Products:</label>
              <select
                    className="form-select"
                  
                    required
                  >
                    <option value="">Select Products</option>
                    <option value="">Cnc Machine</option>
                    <option value="">Driller</option>
                    
                  </select>
            </div>

            <div className="col-lg-3">
              <label></label>
              <div className="form-group">
                <div className="floating-label">
                <label>Quantity</label>
              <input
                type="text"
              
                className="form-control"
               
              ></input>
                </div>
              
              </div>
            </div>

            <div className="col-lg-3 mt-4">
            <button
                type="button"
                className="btn btn-secondary Add"
            
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {/* name */}


        {/* =============================================================================== */}
        <div className="card invoice_card p-3">
          <div className="row first">
            <div className="col-lg-2">
              <label>Paid Till Date:</label>
              <input
                type="date"
                className="form-control"
              ></input>
            </div>

            <div className="col-lg-2">
              <label>Payment Due Date:</label>
              <input
                type="date"
                className="form-control"
              ></input>
            </div>

            <div className="col-lg-2">
              <label>Next Due Date:</label>
              <input
                type="date"
                className="form-control"
              ></input>
            </div>

            <div className="col-lg-3">
              <label>Person Name:</label>
              <input
                type="text"
                className="form-control"
              />
            </div>

            <div className="col-lg-3">
              <label>Contact:</label>
              <input
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>

        {/* ////////////////////////////////////////////////////////////////////////////// */}
        <div className="card invoice_card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>S.NO</th>
                    {/* <th>branch</th> */}
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    <tr >
                      <td>1</td>
                      <td>
                        <input
                          type="text"
                        
                          className="form-control"
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          className="form-control"
                         
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          className="form-control"
                          inputMode="numeric"
                          style={{
                            "-webkit-appearance":
                              "none" /* Chrome, Safari, Edge, Opera */,
                            "-moz-appearance": "textfield" /* Firefox */,
                            appearance: "textfield" /* Standard */,
                            margin: 0 /* Ensure the spinner arrows are not replaced with extra padding */,
                          }}
                          onWheel={(e) => e.preventDefault()} // Prevent the default wheel behavior
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                         
                          className="form-control"
                          readOnly
                        />
                      </td>
                      <td>
                        <i
                          class="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-2"
                          style={{ color: "red" }}
                         
                        ></i>
                       
                      </td>
                    </tr>
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <div className="card invoice_card p-3">
          <div className="row">
            <h5>Previous Payment History</h5>
            <div className="col-lg-6">
              <div className="card invoice_card">
                <div className="invoice">
                  <div className="table">
                    <table className="table table-bordered">
                      <thead >
                        <tr >
                          <th style={{ width: "30px" }}>S.NO</th>
                          <th style={{ width: "400px" }}>Branch</th>
                          <th style={{ width: "400px" }}>Details</th>
                          <th style={{ width: "250px" }}>Date</th>
                          <th style={{ width: "210px" }}>Paid</th>
                          <th style={{ width: "210px" }}>Paytype</th>
                          <th style={{ width: "210px" }}>Paid By</th>
                          <th style={{ width: "210px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                          <tr>
                            <td>1</td>
                            <td>branch_id</td>
                            <td>details</td>
                            <td></td>
                            <td>paid</td>
                            <td>paytype</td>
                            <td>paid_by</td>
                            <td>
                              <button
                                className="btn btn-danger "
                                
                              >
                                <i class="fa-solid fa-xmark"></i>
                              </button>
                            </td>
                          </tr>
                     
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* ////////////////////////////////////subtotal to balance////////////////////////////////////// */}
            {/* subtotal */}
            <div className="col-lg-6 ">
              <div className="row">
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Subtotal</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                   
                    className="form-control"
                  ></input>
                </div>
                {/* gst */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12"
                  style={{ textAlign: "center" }}
                >
                  <p>
                    Gst{" "}
                    <input type="checkbox" />
                  </p>
                </div>
               
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                    
                    className="form-control"
                   
                    disabled 
                  />
                </div>
               
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Discount</p>
                </div>
              
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                   
                    className="form-control"
                    
                  />
                </div>
            
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Nettotal</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                   
                    className="form-control"
                  ></input>
                </div>
                {/* paytype */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Paytype</p>
                </div>{" "}
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <select
                    className="form-control   paytype"
                  
                  >
                    <option value="">Select Paytype</option>
                    <option value="gpay">GPay</option>
                    <option value="phonepe">PhonePe</option>
                    <option value="cash">Cash</option>
                    <option value="paytm">Paytm</option>
                    

                  </select>
                </div>



                {/* paid_by */}

                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Paid By</p>
                </div>{" "}
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <select
                    className="form-control   paytype"
                 
                  >
                    <option value="">Select Payment Paid By</option>
                    <option value="parent"> Parent</option>
                    <option value="student"> Student</option>
                    <option value="Gradian"> Gradian</option>
                    <option value="other"> other</option>
                  </select>
                </div>
                {/* paid */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Paid</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                    className="form-control"
                  
                  ></input>
                  <button
                    className="btn btn-primary gap"
                   
                  >
                    Add
                  </button>
                </div>
                {/* balance */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 "
                  style={{ textAlign: "center" }}
                >
                  <p>Balance</p>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="0.00"
                    type="number"
                  
                    className="form-control"
                  ></input>
                </div>


              


              </div>
            

              <div className="row">
                <div
                  // style={{ marginLeft: "75px" }}
                  className="d-flex justify-content-evenly mt-3 buttonstyle "
                >
                  <button
                    type="button"
                    className="btn btn-danger invoice-button"
                   
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-info text-white invoice-button"
                    style={{ backgroundColor: "#007BFF" }}
                  
                  >
                    Clear
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary invoice-button"
                   
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-success invoice-button"
                  
                  >
                    Save&Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
     
        </>
    )
}

export default AddSalesInvoice