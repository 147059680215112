import { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function User() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>User</b>
              </Link>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className=" col-lg-9 d-flex">
            <h5 className="mt-2">User Details</h5>
            <div className="mx-3">
              <button
                className="btn btn-success add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>

              {/* <button className="btn  add " onClick={handleNewButtonClick}>
              Add
            </button> */}
            </div>
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select className="form-control w-100 mx-2">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------end------------------------------------------- */}

        {/* Table */}
        <div className="card">
          <div className="mt-2">
            <table className="table table-striped responsive">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>User Name</th>
                  <th>Staff Name</th>
                  <th>Mobile No</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Admin</td>
                  <td>Siva</td>
                  <td>9864545454</td>
                  <td>checking</td>
                  <td>
                    <button className="btn bg-success text-white">
                      Active
                    </button>
                  </td>
                  <td>
                    <button className="btn updelete mx-2">
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button className="btn delete">
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal for adding a new user */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row   ">
              <div className="col-lg-6">
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <td className="management">
                      <input type="checkbox" />
                    </td>
                    <th className="px-1">Management</th>
                  </div>
                  <div className="col-lg-5">
                    <td className="staff">
                      <input type="checkbox" />
                    </td>
                    <th className="px-1">Staff</th>
                  </div>
                </div>
                <div className="form-group">
                  <div className="floating-label">
                    <select className="form-select" required>
                      <option value="">Select Branch</option>
                    </select>

                    <label className=""></label>
                  </div>

                  <div className="floating-label">
                    <select className="form-select" required>
                      <option value="">Select emp</option>
                    </select>

                    {/* <label className="">User Name</label> */}
                  </div>

                  <div className="floating-label mt-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="UserName"
                      required
                    />
                  </div>

                  <div className="floating-label mt-4">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 ">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type=""
                      placeholder="password"
                      required
                    />
                  </div>

                  <div className="floating-label mt-4">
                    <select className="form-select" required>
                      <option value="">Select Role </option>
                    </select>
                    <label className=""></label>
                  </div>

                  <div className="floating-label">
                    <select className="form-control" placeholder="">
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                    <label className=""></label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button className="submitbut" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* ----------------update */}

        {/* _------------------------------pagination button -------------------------------- */}
        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li className="">
                    <button className="page-link">Previous</button>
                  </li>

                  <li>
                    <button className="page-link ">1</button>
                  </li>

                  <li className="">
                    <button className="page-link ">Next</button>
                  </li>
                </ul>
              </nav>{" "}
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark ">Page 1 of 1</span>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------------------------------end ---------------------------------- */}
      </div>
    </>
  );
}

export default User;
