import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function IncomeType() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/incomelist" className="text-secondary dashBoard_text">
              <b>Income List / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Income Type</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <h5 className="mt-2"> Income Type</h5>

        <div className="mx-3">
          <button
            className="btn btn-success  add"
            onClick={() => setShowModal(true)}
          >
            Add
          </button>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select className="form-control w-100 mx-2">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Branch</th> */}
                <th>Income Type Name</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Sales</td>
                <td>
                  <button className="btn updelete mx-2">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </button>

                  <button className="btn delete">
                    <i class="fa-solid fa-trash-can"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Pojects</td>
                <td>
                  <button className="btn updelete mx-2">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </button>

                  <button className="btn delete">
                    <i class="fa-solid fa-trash-can"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Services</td>
                <td>
                  <button className="btn updelete mx-2">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </button>

                  <button className="btn delete">
                    <i class="fa-solid fa-trash-can"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for adding a new user */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Income Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="floating-label">
              <input
                className="form-control"
                placeholder=""
                type="text"
                required
              />
              <label className="">Income Type Name</label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button className="submitbut" variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li className="">
                  <button className="page-link">Previous</button>
                </li>

                <li className="">
                  <button className="page-link ">1</button>
                </li>

                <li className="">
                  <button className="page-link ">Next</button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">Page 1 of 1</span>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </div>
  );
}

export default IncomeType;
