import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Products() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Product</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex">
          <h5 className="mt-2">Product</h5>
          <div className="mx-3">
            <button
              className="btn btn-success add mx-3"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
        </div>
        <div className="col-lg-2">
          {/* <button
          className="btn btn-secondary text-white"
          onClick={handleAssetType}
        >
          Asset Type
        </button> */}
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select className="form-control w-100 mx-2">
                {/* <option value="5">5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Product Id</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>M.R.P</th>
                  <th>Rate</th>
                  <th>Qty</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>010SE</td>
                  <td>Ev board</td>
                  <td>Electronic</td>
                  <td>759</td>
                  <td>599</td>
                  <td>1</td>

                  <td>
                    <button className="btn bg-success text-white">
                      Active
                    </button>
                  </td>
                  <td>
                    <button className="btn updelete ">
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button className="btn delete mx-1">
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button className="btn btn-transpront">
                      <i class="fa-solid fa-circle-info"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="insermodal">
        <Modal
          className="addmodals"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>New Product</Modal.Title>
          </Modal.Header>

          <form>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6 ">
                  <div className="floating-label ">
                    <label className="form-label">
                      <b></b>
                    </label>

                    <select className="form-select" placeholder="" required>
                      <option value="">Product Id </option>

                      <option key="" value="">
                        001
                      </option>
                      <option key="" value="">
                        002
                      </option>
                    </select>
                    <label className=""></label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Product Name"
                      required
                    />
                  </div>

                  <div className="floating-label mt-4">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Category"
                      required
                    />
                  </div>

                  <div className="floating-label mt-4">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="M.R.P"
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-6 ">
                  <div className="floating-label ">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Rate"
                      required
                    />
                  </div>

                  <div className="floating-label mt-4">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Qty"
                      required
                    />
                  </div>

                  <div className="floating-label ">
                    <label className="form-label">
                      <b></b>
                    </label>

                    <select className="form-select" placeholder="" required>
                      <option value="">Status</option>

                      <option key="" value="">
                        Active
                      </option>
                      <option key="" value="">
                        InActive
                      </option>
                    </select>
                    <label className=""></label>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                className="cancelbut"
                variant="secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button className="submitbut" type="submit" variant="primary">
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li className="">
                  <button className="page-link">Previous</button>
                </li>

                <li className="">
                  <button className="page-link ">1</button>
                </li>

                <li className="">
                  <button className="page-link ">Next</button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">Page 1 of 1</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
