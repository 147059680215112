import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/sidebar/Sidebar";
import Dashboard from "./components/pages/Dashboard";
import Role from "./components/pages/Role";
import User from "./components/pages/User";
import Branch from "./components/pages/Branch";
import Upcoming from "./components/pages/Upcoming";
import NewEnquiry from "./components/pages/NewEnquiry";
import Enquiry from "./components/pages/Enquiry";
import Followsups from "./components/pages/Followups";
import Funnel from "./components/pages/Funnel";
import WalkInDetails from "./components/pages/WalkInDetails";
import Sourcetype from "./components/pages/Sourcetype";
import EnquiryPurpose from "./components/pages/EnquiryPurpose";
import Ongoing from "./components/pages/Ongoing";
import Completed from "./components/pages/Completed";
import PurchaseOrder from "./components/pages/PurchaseOrder";
import Vendor from "./components/pages/Vendor";
import SalesOrder from "./components/pages/SalesOrder";
import Customer from "./components/pages/Customer";
import UpcomingServices from "./components/pages/UpcomingServices";
import OngoingServices from "./components/pages/OngoingServices";
import CompleteServices from "./components/pages/CompleteServices";
import AssetList from "./components/pages/AssetList";
import AssetType from "./components/pages/AssetType";
import ExpenseList from "./components/pages/ExpenseList";
import ExpenseType from "./components/pages/ExpenseType";
import VendorDetails from "./components/pages/VendorDetails";
import PayType from "./components/pages/PayType";
import IncomeList from "./components/pages/IncomeList";
import Invoice from "./components/pages/Invoice";
import DailyReport from "./components/pages/DailyReport";
import EnquiryReport from "./components/pages/EnquiryReport";
import AttendanceReport from "./components/pages/AttendanceReport";
import ExpenseReport from "./components/pages/ExpenseReport";
import ProjectReport from "./components/pages/ProjectReport";
import StaffReport from "./components/pages/StaffReport";
import IncomeReport from "./components/pages/IncomeReport";
import AddRole from "./components/pages/AddRole";
import Supplier from "./components/pages/Supplier";
import Stock from "./components/pages/Stock";
import IncomeType from "./components/pages/IncomeType";
import SalesInvoice from "./components/pages/SalesInvoice";
import AddSalesInvoice from "./components/pages/AddSalesInvoice";
import AddPurchaseOrder from "./components/pages/AddPurchaseOrder";
import Products from './components/pages/Products';
import Category from "./components/pages/Category";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Sidebar>
              <Dashboard />
            </Sidebar>
          }
        />
        <Route
          path="/role"
          element={
            <Sidebar>
              <Role />
            </Sidebar>
          }
        />
        <Route
          path="/addrole"
          element={
            <Sidebar>
              <AddRole />
            </Sidebar>
          }
        />
        <Route
          path="/user"
          element={
            <Sidebar>
              <User />
            </Sidebar>
          }
        />
        <Route
          path="/branch"
          element={
            <Sidebar>
              <Branch />
            </Sidebar>
          }
        />
        <Route
          path="/upcomings"
          element={
            <Sidebar>
              <Upcoming />
            </Sidebar>
          }
        />
        <Route
          path="/newenquiry"
          element={
            <Sidebar>
              <NewEnquiry />
            </Sidebar>
          }
        />
        <Route
          path="/enquiry"
          element={
            <Sidebar>
              <Enquiry />
            </Sidebar>
          }
        />
        <Route
          path="/followsups"
          element={
            <Sidebar>
              <Followsups />
            </Sidebar>
          }
        />
        <Route
          path="/funnel"
          element={
            <Sidebar>
              <Funnel />
            </Sidebar>
          }
        />
        <Route
          path="/Walkin"
          element={
            <Sidebar>
              <WalkInDetails />
            </Sidebar>
          }
        />
        <Route
          path="/sourcetype"
          element={
            <Sidebar>
              <Sourcetype />
            </Sidebar>
          }
        />
        <Route
          path="/Enquirypurpose"
          element={
            <Sidebar>
              <EnquiryPurpose />
            </Sidebar>
          }
        />
        <Route
          path="/completed"
          element={
            <Sidebar>
              <Completed />
            </Sidebar>
          }
        />
        <Route
          path="/ongoing"
          element={
            <Sidebar>
              <Ongoing />
            </Sidebar>
          }
        />
        <Route
          path="/purchaseorder"
          element={
            <Sidebar>
              <PurchaseOrder />
            </Sidebar>
          }
        />
        <Route
          path="/supplier"
          element={
            <Sidebar>
              <Supplier />
            </Sidebar>
          }
        />
        <Route
          path="/stocks"
          element={
            <Sidebar>
              <Stock />
            </Sidebar>
          }
        />
        <Route
          path="/vendordetails"
          element={
            <Sidebar>
              <Vendor />
            </Sidebar>
          }
        />
        <Route
          path="/salesorder"
          element={
            <Sidebar>
              <SalesOrder />
            </Sidebar>
          }
        />
        <Route
          path="/customer"
          element={
            <Sidebar>
              <Customer />
            </Sidebar>
          }
        />
        <Route
          path="/salesinvoice"
          element={
            <Sidebar>
              <SalesInvoice />
            </Sidebar>
          }
        />
        <Route
          path="/addsalesinvoice"
          element={
            <Sidebar>
              <AddSalesInvoice />
            </Sidebar>
          }
        />
        <Route
          path="/upcomingservices"
          element={
            <Sidebar>
              <UpcomingServices />
            </Sidebar>
          }
        />
         <Route
          path="/addpurchaseorder"
          element={
            <Sidebar>
              <AddPurchaseOrder />
            </Sidebar>
          }
        />
        <Route
          path="/products"
          element={
            <Sidebar>
              <Products />
            </Sidebar>
          }
        />
        <Route
          path="/category"
          element={
            <Sidebar>
              <Category />
            </Sidebar>
          }
        />
        
        <Route
          path="/ongoingservices"
          element={
            <Sidebar>
              <OngoingServices />
            </Sidebar>
          }
        />
        <Route
          path="/completeservices"
          element={
            <Sidebar>
              <CompleteServices />
            </Sidebar>
          }
        />
        <Route
          path="/assetlist"
          element={
            <Sidebar>
              <AssetList />
            </Sidebar>
          }
        />
        <Route
          path="/assettype"
          element={
            <Sidebar>
              <AssetType />
            </Sidebar>
          }
        />
        <Route
          path="/expenselist"
          element={
            <Sidebar>
              <ExpenseList />
            </Sidebar>
          }
        />
        <Route
          path="/expensetype"
          element={
            <Sidebar>
              <ExpenseType />
            </Sidebar>
          }
        />
        <Route
          path="/vendordetails"
          element={
            <Sidebar>
              <VendorDetails />
            </Sidebar>
          }
        />
        <Route
          path="/paytype"
          element={
            <Sidebar>
              <PayType />
            </Sidebar>
          }
        />
        <Route
          path="/incomelist"
          element={
            <Sidebar>
              <IncomeList />
            </Sidebar>
          }
        />
        <Route
          path="/incometype"
          element={
            <Sidebar>
              <IncomeType />
            </Sidebar>
          }
        />
        <Route
          path="/invoice"
          element={
            <Sidebar>
              <Invoice />
            </Sidebar>
          }
        />
        <Route
          path="/dailyreport"
          element={
            <Sidebar>
              <DailyReport />
            </Sidebar>
          }
        />
        <Route
          path="/enquiryreport"
          element={
            <Sidebar>
              <EnquiryReport />
            </Sidebar>
          }
        />
        <Route
          path="/attendancereport"
          element={
            <Sidebar>
              <AttendanceReport />
            </Sidebar>
          }
        />
        <Route
          path="/expensereport"
          element={
            <Sidebar>
              <ExpenseReport />
            </Sidebar>
          }
        />
        <Route
          path="/projectreport"
          element={
            <Sidebar>
              <ProjectReport />
            </Sidebar>
          }
        />
        <Route
          path="/staffreport"
          element={
            <Sidebar>
              <StaffReport />
            </Sidebar>
          }
        />
        <Route
          path="/incomereport"
          element={
            <Sidebar>
              <IncomeReport />
            </Sidebar>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
