import { useNavigate } from "react-router-dom";

const modules = [
  {
    name: "Dashboard",
    id: 1,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Roles",
    id: 2,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Users",
    id: 3,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Branch",
    id: 4,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "New Enquiry",
    id: 5,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquiry",
    id: 6,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Followups",
    id: 7,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Funnel",
    id: 8,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Walkin-Details",
    id: 9,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Sourcetype",
    id: 10,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquirypurpose",
    id: 11,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "PayType",
    id: 12,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Studying Students",
    id: 13,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Students Dairy",
    id: 14,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Completed",
    id: 15,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Course",
    id: 16,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Category",
    id: 17,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Schedule",
    id: 18,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Staff",
    id: 19,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Attendance",
    id: 20,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Designation",
    id: 21,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Leave",
    id: 22,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Salary Details",
    id: 23,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Job Portal",
    id: 24,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "New Promotions",
    id: 25,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Asset Type",
    id: 26,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Asset List",
    id: 27,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense Type",
    id: 28,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense List",
    id: 29,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "VendorDetails",
    id: 30,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income Type",
    id: 31,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income List",
    id: 32,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Invoice",
    id: 33,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Daily Report",
    id: 34,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquiry Report",
    id: 35,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Attendance Report",
    id: 36,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense Report",
    id: 37,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Student Report",
    id: 38,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Staff Report",
    id: 39,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income Report",
    id: 40,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Invoice Report",
    id: 41,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
];

function AddRole() {
  const navigate = useNavigate();
  return (
    <>
      <div className="tops">
        <div className="admin-roles-main">
          {/* <h1 className="text-center">Update Roles</h1> */}

          <h4 className="">Add Roles</h4>
        </div>
        <div
          className="card p-3 mt-3"
          style={{ padding: "10px 10px", marginBottom: "20px" }}
        >
          <form>
            {/* <h3 style={{ fontWeight: 600 }}>Admin Roles</h3> */}

            <div className="row mt-2">
              <div className="form-label">
                <b>Role Name:</b>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  id="role"
                  className="form-control"
                  placeholder="Role Name"
                  name="role"
                />
              </div>

              <div className="mt-4">
                <div className="card-container table-responsive">
                  <table className=" admin-role-card">
                    <thead>
                      <tr className="text-center">
                        <th colSpan="7">Privilages</th>
                      </tr>
                      <tr className="text-center">
                        <th>Module</th>
                        <th>All</th>
                        <th>Add</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>View</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-start">
                        <td className="role-data-name">
                          {modules.find((item) => item.id === module.id).name}
                        </td>
                        <td className="role-data-all">
                          <input type="checkbox" checked={module.actions.all} />
                        </td>
                        <td className="role-data-add">
                          <input type="checkbox" checked={module.actions.add} />
                        </td>
                        <td className="role-data-edit">
                          <input
                            type="checkbox"
                            checked={module.actions.edit}
                          />
                        </td>
                        <td className="role-data-delete">
                          <input
                            type="checkbox"
                            checked={module.actions.delete}
                          />
                        </td>
                        <td className="role-data-view">
                          <input
                            type="checkbox"
                            checked={module.actions.view}
                          />
                        </td>
                        <td className="role-data-download">
                          <input
                            type="checkbox"
                            checked={module.actions.download}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/role")}
              >
                Back
              </button>

              <button type="submit" className="btn btn-info mx-4">
                Update
              </button>

              <button type="submit" className="btn btn-info mx-4">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddRole;
