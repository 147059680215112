import { Link, useNavigate } from "react-router-dom";

function Role() {
  const navigate = useNavigate();

  const handleAddRole = () => {
    navigate("/addrole");
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>User</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="top">
        <div className="d-flex align-items-center mb-3">
          <h5 className="mt-2">Roles</h5>

          <button className="btn btn-success add mx-3" onClick={handleAddRole}>
            Add
          </button>
        </div>

        {/* Search and pagination */}
        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show:</label>
              <select className="form-control w-100 mx-2">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-7 offset-lg-7">
              <input
                className="form-control"
                placeholder="Search..."
                type="search"
              />
            </div>
          </div>
        </div>

        <div className="card">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Role Name</th>
                  <th>Module</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Admin</td>
                  <td>
                    Dashboard, Roles, Users, Branch, New Enquiry, Enquiry,
                    Followups, Funnel, Walkin-Details
                  </td>
                  <td>
                    <button className="btn ">
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button className="btn ">
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-9"></div>
          <div className="col-lg-3">
            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <button className="page-link">Previous</button>
                </li>

                <li className="">
                  <button className="page-link">1</button>
                </li>

                <li className="page-item">
                  <button className="page-link">Next</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Role;
