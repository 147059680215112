import React, { useState, useEffect } from "react";
import { FaTh, FaBars } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Navbar from "../Navbar";
import "../../assets/css/Sidebar.css";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(false); // Close sidebar on route change
  }, [location]);

  const toggle = () => setIsOpen(!isOpen);

  const handleDropdownToggle = (index) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // const handleUserItemClick = () => {
  //   setDropdownStates({});
  // };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const menuItem = [
    {
      path: "/",
      name: "Dashboard",
      icon: (
        <FaTh
          className={location.pathname === "/" ? "text-white" : "text-danger"}
        />
      ),
    },
    {
      path: "/role",
      name: "Roles",
      icon: (
        <i
          className={`fa-solid fa-layer-group ${
            location.pathname === "/role" ? "text-white" : "text-danger"
          }`}
        />
      ),
    },
    {
      path: "/user",
      name: "Users",
      icon: (
        <i
          className={`fa-solid fa-user-tie ${
            location.pathname === "/user" ? "text-white" : "text-danger"
          }`}
        />
      ),
    },
    {
      path: "/branch",
      name: "Branch",
      icon: (
        <i
          className={`fa-solid fa-sitemap ${
            location.pathname === "/branch" ? "text-white" : "text-danger"
          }`}
        />
      ),
    },
    {
      path: "/student",
      name: "Leads",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        { path: "/newenquiry", name: "New Enquiry" },
        { path: "/enquiry", name: "Enquiry" },
        { path: "/followsups", name: "Followups" },
        { path: "/funnel", name: "Funnel" },
        { path: "/Walkin", name: "Walkin-Details" },
        { path: "/sourcetype", name: "Sourcetype" },
        { path: "/enquirypurpose", name: "Enquirypurpose" },
      ],
    },
    {
      path: "/#",
      name: "Projects",
      icon: <i class="fa-solid fa-list-check"></i>,
      subItems: [
        { path: "/upcomings", name: "Upcomings" },
        { path: "/ongoing", name: "Ongoing" },
        { path: "/completed", name: "Completed" },
      ],
    },
    {
      path: "/#",
      name: "Sales",
      icon: <i class="fa-solid fa-sack-dollar"></i>,
      subItems: [
        { path: "/salesorder", name: "Sales Order" },
        { path: "/customer", name: "Customer" },
        // { path: "/salesinvoice", name: "Sales Invoice" },
      ],
    },
    {
      path: "/#",
      name: "Services",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        { path: "/upcomingservices", name: "Upcomings" },
        { path: "/ongoingservices", name: "Ongoing" },
        { path: "/completeservices", name: "Completed" },
      ],
    },
    {
      path: "/stocks",
      name: "Stocks",
      icon: (
        <FaTh
          className={location.pathname === "/" ? "text-white" : "text-danger"}
        />
      ),
    },
    {
      path: "/#",
      name: "Products",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        { path: "/products", name: "Product List" },
        { path: "/category", name: "Category" },
      ],
    },
    {
      path: "/#",
      name: "Purchase",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        { path: "/purchaseorder", name: "Purchase Order" },
        { path: "/supplier", name: "Supplier" },
      ],
    },
    {
      path: "/assetlist",
      name: "Asset List",
      icon: (
        <FaTh
          className={
            location.pathname === "/dashboard" ? "text-white" : "text-danger"
          }
        />
      ),
    },
    {
      path: "/#",
      name: "Expense List",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        { path: "/expenselist", name: "Expense List" },
        { path: "/vendordetails", name: "Vendor Details" },
        { path: "/paytype", name: "Paytype" },
      ],
    },
    {
      path: "/incomelist",
      name: "Income List",
      icon: (
        <FaTh
          className={
            location.pathname === "/dashboard" ? "text-white" : "text-danger"
          }
        />
      ),
    },
    {
      path: "/invoice",
      name: "Invoice",
      icon: (
        <FaTh
          className={
            location.pathname === "/dashboard" ? "text-white" : "text-danger"
          }
        />
      ),
    },
    {
      path: "/#",
      name: "Report",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        { path: "/dailyreport", name: "Daily Report" },
        { path: "/enquriyreport", name: "Enquiry Report" },
        { path: "/attendancereport", name: "Attendance Report" },
        { path: "/expensereport", name: "Expense Report" },
        { path: "/projectreport", name: "Project Report" },
        { path: "/staffreport", name: "Staff Report" },
        { path: "/incomereport", name: "Income Report" },
      ],
    },
    {
      path: "/logout",
      name: "Logout",
      icon: (
        <i
          className={`fa-solid fa-right-from-bracket ${
            location.pathname === "/logout" ? "text-white" : "text-danger"
          }`}
        />
      ),
    },
    // Add more items as needed...
  ];

  if (
    ["/register", "*"].includes(location.pathname) ||
    location.pathname.startsWith("/invoice/invoiceprint/") ||
    location.pathname.startsWith("/invoice/bill/")
  ) {
    return (
      <main style={{ padding: "20px", marginTop: "0px" }}>{children}</main>
    );
  }

  return (
    <>
      <Navbar toggle={isOpen} />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <p>Are you sure you want to logout?</p>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary">Logout</Button>
        </Modal.Footer>
      </Modal>

      <div className="container-main">
        <div
          className="sidebar"
          style={{
            position: "fixed",
            top: "70px",
            bottom: 0,
            left: 0,
            width: isOpen ? "44px" : "250px",
            zIndex: 1000,
          }}
        >
          <div className="top_section">
            <p
              style={{ display: isOpen ? "none" : "block" }}
              className={`logo mx-1 ${isOpen ? "" : "d-flex"}`}
            >
              <i className="fa-regular fa-circle-user sidebarusericon mx-1 mt-1"></i>
              <div className="admincont">
                {" "}
                <img
                  src={require("../../assets/img/scimitar logo slogan.png")}
                  alt="Company Logo"
                  style={{
                    width: "150px",
                    height: "40px",
                    marginLeft: "-30px",
                    marginTop: "5px",
                  }}
                  onClick={handleLogoClick}
                ></img>
              </div>
            </p>
            <div
              style={{ marginLeft: isOpen ? "7px" : "70px" }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>

          {menuItem.map((item, index) => (
            <div key={index}>
              {item.subItems ? (
                <>
                  <div
                    className="link"
                    onClick={() => handleDropdownToggle(index)}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className={`link_text ${isOpen ? "d-none" : ""}`}>
                      {item.name}
                    </div>
                    <div className="dropdown_icon">
                      {dropdownStates[index] ? (
                        <i className="fa-solid fa-chevron-up"></i>
                      ) : (
                        <i className="fa-solid fa-chevron-down"></i>
                      )}
                    </div>
                  </div>
                  {dropdownStates[index] && (
                    <div className="subMenu">
                      {item.subItems.map((subItem, subIndex) => (
                        <NavLink
                          key={subIndex}
                          to={subItem.path}
                          className="link submenu"
                          activeClassName="active"
                        >
                          <div className="icon">{subItem.icon}</div>
                          <div
                            className={`link_text ${isOpen ? "d-none" : ""}`}
                          >
                            {subItem.name}
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <NavLink
                  to={item.path}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{item.icon}</div>
                  <div className={`link_text ${isOpen ? "d-none" : ""}`}>
                    {item.name}
                  </div>
                </NavLink>
              )}
            </div>
          ))}
        </div>
        <main className={`main ${isOpen ? "isClose" : "isOpen"}`}>
          {children}
        </main>
      </div>
    </>
  );
};

export default Sidebar;
