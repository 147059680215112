import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Dashboard.css";

function Dashboard() {
  // Data for the chart
  const horizontal_bar_data = [
    ["Month", "Population"],
    ["Jan", 70000],
    ["Feb", 117000],
    ["Mar", 66000],
    ["Apr", 10030],
    ["May", 200000],
    ["Jun", 95000],
    ["Jul", 130000],
  ];

  // Options for the chart
  const horizontal_bar_options = {
    title: "No of Students",
    curveType: "function",
    legend: { position: "bottom" },
    pointSize: 10, // Add points on the line
    pointsVisible: true, // Ensure points are visible
    colors: ["#850000"],
  };


  const navigate = useNavigate()

  return (
    <>
      <div
        className="top"
        style={{ background: "#f2f0f0", marginTop: "-80px" }}
      >
        <div className="dashboard">
          <h5>
            <b>Dashboard</b>
          </h5>
        </div>

        <div className="row  ">
          <div className="col-lg-12  col-md-8 col-sm-6">
            <div className="row">
              <div className="col-lg-3">
                <div className="card one p-2" onClick={() => navigate("/newenquiry")}>
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                          backgroundColor: '#AE0000'
                      }}
                    >
                      <i
                        className="fa-solid fa-graduation-cap iconcards"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                    
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name" >
                      <b>Leads</b>
                    </h6>
                  </div>
                </div>
              </div>

              {/* Course */}

              <div className="col-lg-3">
                <div className="card one p-2" onClick={() => navigate("/followsups")}>
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                          backgroundColor: '#AE0000'
                      }}
                    >
                      <i
                        className="fa-solid fa-user-plus iconcards"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                          
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Pending Followups</b>{" "}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card one p-2" onClick={() => navigate("/upcomings")}>
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                          backgroundColor: '#AE0000'
                      }}
                    >
                      <i
                        className="fa-brands fa-readme iconcards"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Project due Followups</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card one p-2" onClick={() => navigate("/products")}>
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                          backgroundColor: '#AE0000'
                      }}
                    >
                      <i
                        className="fa-solid fa-toggle-on iconcards"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Product</b>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12  col-md-8 col-sm-6 mt-2">
            <div className="row">
              <div className="col-lg-3">
                <div className="card one p-2" onClick={() => navigate("/upcomings")}>
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                          backgroundColor: '#AE0000'
                      }}
                    >
                      <i
                        className="fa-solid fa-chalkboard-user iconcards"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Projects</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card one p-2 " onClick={() => navigate("/upcomingservices")}>
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                          backgroundColor: '#AE0000'
                      }}
                    >
                      <i
                         className=" fa-solid fa-gears iconcards"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Services</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card one p-2 " onClick={() => navigate("/incomelist")}>
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                          backgroundColor: '#AE0000'
                      }}
                    >
                      <i
                       
                        className=" fa-solid fa-hand-holding-dollar iconcards"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Income</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card one p-2" onClick={() => navigate("/expenselist")} >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                       backgroundColor: '#AE0000'
                      }}
                    >
                      <i
                       
 className=" fa-solid fa-scale-unbalanced iconcards"
                        style={{
                          color: "white",
                         
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Expense</b>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* card part 2 */}

        <div className="charts pt-2"></div>
        <div className="row mt-4">
          <div className="col-lg-6">
            <h5 className="">Pending Payment</h5>
            <div className="card p-3 mb-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card p-3">
                    <h5 className="text-center bg-danger text-white p-3">
                      Salem
                    </h5>
                    <hr></hr>
                    <div className="card">
                      <div className="row details-data">
                        <div className="col-lg-6 mt-1">
                          <b>Pending Payment</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {/* {paymentoverallDetails?.fees || ""} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card p-3">
                    <h5 className="text-center bg-danger text-white p-3">
                      Erode
                    </h5>
                    <hr></hr>
                    <div className="card">
                      <div className="row details-data">
                        <div className="col-lg-6 mt-1">
                          <b>Pending Payment</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {/* {paymentoverallDetails?.fees || ""} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mb-4">
            <h5 className="">Income Expenses</h5>
            <div className="card">
              <div className="App" style={{ width: "400px", header: "600px" }}>
                {/* <div className="mt-5">
                <PieChart />
              </div> */}
                <Chart
                  chartType="BarChart" // Use the selected chart type
                  width="100%"
                  height="400px"
                  data={horizontal_bar_data}
                  options={horizontal_bar_options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
