import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SalesInvoice() {
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Invoice</b>
              </Link>
              {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <h5 className="mt-2">New Sales Order</h5>
          <div className="mx-3">
            <button
              className="btn btn-success "
              onClick={() => navigate("/addsalesinvoice")}
            >
              Add
            </button>
          </div>
        </div>

        {/* Search and per page */}
        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select className="form-control w-100 mx-1">
                  {/* <option value='5'>5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-1">
              <select className="form-control">
                <option value="showall">Select All</option>
                <option value="course">Course</option>
                {/* <option value="projectname">Projectname</option> */}
                <option value="rent">Rent</option>
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8"
                      placeholder="Search ..."
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Invoice No</th>
                  <th>Branch</th>
                  <th>Date</th>
                  <th>Course</th>
                  {/* <th>Description</th> */}
                  <th>Name</th>
                  <th>Total</th>
                  <th>Paid</th>
                  <th>Balance</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>invoice_no</td>
                  <td>branch_name</td>
                  <td></td>
                  <td>dependent</td>
                  <td>name</td>
                  <td>nettotal</td>
                  <td>paid</td>
                  <td>balance</td>
                  <td className="text-center ">
                    {/* <button className='btn btn-info change'><i className="fa-solid fa-circle-info"></i></button> */}

                    <button className="btn updelete">
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button className="btn  Print ">
                      <i className="fa-solid fa-print"></i>
                    </button>

                    <button className="btn delete change">
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1">Employee Id:</h5> <h5> </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancelbut" variant="secondary">
              Close
            </Button>
            <Button className="submitbut" variant="primary">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Pagination */}
        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li className="">
                    <button className="page-link">Previous</button>
                  </li>

                  <li className="">
                    <button className="page-link">1</button>
                  </li>

                  <li className="">
                    <button className="page-link">Next</button>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark">Page 1 of 1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesInvoice;
